/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Flex } from '@rebass/grid/emotion'

import styled from '@emotion/styled'

import Twitter from '@manuscripts/assets/react/Twitter'

const BioContainer = styled.div`
  text-align: center;
`

const CenteredContainer = styled.div`
  margin-left: 0px;
  margin-right: 0px;
  display: block;
  width: 100%;
  text-align: center;
`

export const StyledSocialMediaFollowershipGroup = styled(Flex)`
  font-size: 18px;
  font-weight: light;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0px;
  margin-top: 0px;
  padding-bottom: 2em;
`

export const StyledSocialMediaFollowershipBox = styled(Flex)`
  vertical-align: middle;
  margin-top: 0px;
  padding-top: 0px;
  padding-left: 5px;
  padding-right: 5px;
  color: #5e6f7e;
  margin-left: auto;
  margin-right: auto;
`

const SocialMediaIconContainer = styled.div`
  padding: 5px;
  padding-top: 10px;
`

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          description
          author
          siteUrl
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <BioContainer>
      <div>
        {data.site.siteMetadata.description} by <a href={data.site.siteMetadata.siteUrl}>{author}</a>.
      </div>

      <CenteredContainer>
        <StyledSocialMediaFollowershipGroup flexDirection={'row'}>
          <StyledSocialMediaFollowershipBox alignItems={'center'}>
            <SocialMediaIconContainer>
              <Twitter width={20} />
            </SocialMediaIconContainer>
            <a href={`https://twitter.com/${social.twitter}`}>Follow @{social.twitter} on Twitter</a>
          </StyledSocialMediaFollowershipBox>
        </StyledSocialMediaFollowershipGroup>
      </CenteredContainer>
    </BioContainer>
  )
}

export default Bio
