import React from "react"
import { Link } from "gatsby"

import { rhythm } from "../utils/typography"
import styled from '@emotion/styled'
import AppIcon from '@manuscripts/assets/react/AppIcon'

import Helmet from 'react-helmet'

import LandingDecorationsLeft from '@manuscripts/assets/react/LandingDecorationsLeft'
import LandingDecorationsRight from '@manuscripts/assets/react/LandingDecorationsRight'

const Root = styled.div`
  margin-left: 100px;
  padding-right: 10em;

  a:link {
    color: #6c6c6c;
    text-decoration: none;
  }
  a:visited {
    color: #6c6c6c;
    text-decoration: none;
  }

  h1 {
    margin-bottom: 0px;
  }
`

const LandingDecorationsLeftContainer = styled.div`
  position: absolute;
  pointer-events: none;
  
  @media (max-width: 600px) {
    & > svg {
      display: none;
    }
  }
`

const LandingDecorationsRightContainer = styled.div`
  position: absolute;
  right: 0px;
  pointer-events: none;
  
  @media (max-width: 600px) {
    & > svg {
      display: none;
    }
  }
`

const AppIconContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0px;
  position: relative;
  display: inline-block;
  text-align: center;
`

const TaglineContainer = styled.div`
  padding-top: 1.5em;
  padding-bottom: 1em;
`

const StyledTagline = styled.div`
  color: #5e6f7e;
  font-family: Lato, -apple-system, sans-serif;
  font-size: 36pt;
  line-height: 1.2;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const Main = styled.main`
  margin-left: 30px;
  margin-right: 30px;
  
  @media (max-width: 600px) {
    margin: 0;
  }
`

const Footer = styled.footer`
  margin-left: 30px;
  margin-right: 30px;
`

const Tagline = ({ title }) => {
  return (<StyledTagline>
    <div>{title}</div>
  </StyledTagline>);
}

class Layout extends React.Component {
  render() {
    const { location, siteUrl, title, description, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <React.Fragment>
          <Helmet title={''} meta={[
            { property: 'og:type', content: 'website' },
            { property: 'og:url', content: siteUrl },
            { property: 'og:title', content: title },
            { property: 'og:description', content: description },
            { property: 'og:image', content: AppIcon },
            { property: 'twitter:card', content: 'summary_large_image' },
            { property: 'twitter:url', content: siteUrl },
            { property: 'twitter:title', content: title },
            { property: 'twitter:description', content: description },
            { property: 'twitter:image', content: AppIcon }
]}>

          </Helmet>
          <AppIconContainer>
            <AppIcon width={150} height={150} />
          </AppIconContainer>

          <TaglineContainer>
            <Link
                style={{
                  boxShadow: `none`,
                  textDecoration: `none`,
                  color: `inherit`,
                }}
                to={`/`}
              >
              <Tagline title={title} />
            </Link>
          </TaglineContainer>

        </React.Fragment>
      )
    } else {
      header = (
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }
    return (
      <React.Fragment>
        <LandingDecorationsLeftContainer>
          <LandingDecorationsLeft />
        </LandingDecorationsLeftContainer>

        <LandingDecorationsRightContainer>
          <LandingDecorationsRight />
        </LandingDecorationsRightContainer>
        <Root
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <Main>{children}</Main>
        <Footer>
          © {new Date().getFullYear()} Atypon Systems LLC
        </Footer>
      </Root>

      </React.Fragment>
    )
  }
}

export default Layout
